define("discourse/plugins/discourse-zolidar/discourse/templates/connectors/topic-category/zolidar-topic-category", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @outletArgs.topic.tags as |tag|}}
    <a
      href={{concat "https://zolidar.com/community/wiki/glossary/" tag}}
      class="btn btn-small zolidar-wiki-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{dIcon "file"}}
      {{tag}}
    </a>
  {{/each}}
  
  */
  {
    "id": "OLRIHFwI",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1,[\"topic\",\"tags\"]]],null]],null],null,[[[1,\"  \"],[10,3],[15,6,[28,[37,3],[\"https://zolidar.com/community/wiki/glossary/\",[30,2]],null]],[14,0,\"btn btn-small zolidar-wiki-link\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[\"file\"],null]],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]],null]],[\"@outletArgs\",\"tag\"],false,[\"each\",\"-track-array\",\"a\",\"concat\",\"dIcon\"]]",
    "moduleName": "discourse/plugins/discourse-zolidar/discourse/templates/connectors/topic-category/zolidar-topic-category.hbs",
    "isStrictMode": false
  });
});