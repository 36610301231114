define("discourse/plugins/discourse-zolidar/discourse/templates/connectors/user-profile-controls/zolidar-user-profile-controls", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.model.user_fields.[1]}}
    <DButton
      @href={{@outletArgs.model.user_fields.[1]}}
      @title="discourse_zolidar.profile.link.title"
      @label="discourse_zolidar.profile.link.label"
      @icon="user"
      class="btn-primary"
    />
  {{/if}}
  
  */
  {
    "id": "OjXhm3TX",
    "block": "[[[41,[30,1,[\"model\",\"user_fields\",\"1\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@href\",\"@title\",\"@label\",\"@icon\"],[[30,1,[\"model\",\"user_fields\",\"1\"]],\"discourse_zolidar.profile.link.title\",\"discourse_zolidar.profile.link.label\",\"user\"]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-zolidar/discourse/templates/connectors/user-profile-controls/zolidar-user-profile-controls.hbs",
    "isStrictMode": false
  });
});